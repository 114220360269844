<template>
  <div class="">
    <transition name="component-trans" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  async created() {
    await this.getPostCategoriesByTreeList();
  },
  methods: {
    async getPostCategoriesByTreeList() {
      await this.$store.dispatch("POST_CATEGORY/getPostCategoriesByTreeList");
    }
  }
};
</script>

<style lang="scss" scoped></style>
